<template>
  <div class="faqs">
    <div
      v-for="item in questions"
      :key="item.key"
    >
      <v-list-item
        class="question"
        @click="openFaq(item)"
      >
        <v-list-item-icon class="icon">
          <v-icon size="16">mdi-chevron-right</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="py-0">{{item.question}}</v-list-item-content>
      </v-list-item>
    </div>
    <drawer :open.sync="isOpen" title="FAQ" class="faq-drawer">
      <div v-if="isOpen" class="pa-4">
        <div class="text-lg font-weight-bold mb-4">{{question.question}}</div>
        <div v-html="question.answer" />
      </div>
    </drawer>
  </div>
</template>

<script>
import Drawer from '../wrapper/Drawer'
export default {
  name: 'Faqs',
  components: { Drawer },
  props: {
    faqs: {
      default: () => ([])
    },
  },
  data: () => ({
    question: null,
  }),
  methods: {
    openFaq (faq) {
      this.question = faq || null
    }
  },
  computed: {
    questions () {
      return this.faqs.map(key => {
        return this.$store.getters['account/lists_group_option']('faqs', key)
      })
    },
    isOpen: {
      get () {
        return Boolean(this.question)
      },
      set (val) {
        if (!val) {
          this.question = null
        }
      }
    },
  }
}
</script>

<style lang="scss">
.faqs {
  padding: 0;
  .question {
    min-height: 0px;
    color: var(--orange) !important;
    cursor: pointer;
    display: inline-flex;
    padding: 0px 4px;
    .icon {
      margin: 3px 0px;
    }
  }
  .faq-drawer {
    text-align: left;
  }
}
</style>
