<script>
import { VExpansionPanels } from 'vuetify/lib'
export default {
  name: 'AccordionMain',
  extends: VExpansionPanels,
  props: {
    accordion: {
      default: true,
    },
    tile: {
      default: true,
    },
    flat: {
      default: true,
    },
    focusable: {
      default: true,
    },
  },
  watch: {
    value (newVal) {
      if (Number.isInteger(newVal)) {
        setTimeout(() => {
          this.$vuetify.goTo('.accordion-main-panel.v-item--active')
        }, 400)
      }
    }
  },
}
</script>

<style lang="scss">
</style>
