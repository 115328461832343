<template>
  <div>
    <div class="inner">
      <div class="d-flex align-center">
        <h1 class="font-cooper">Digital Vault</h1>
        <v-spacer />
      </div>
    </div>
    <div class="pa-4">
      <p>Your Willed Digital Vault is a secure place to keep important information to make the administration of your estate easier for your Executors and loved ones, when the time comes.</p>
      <faqs :faqs="['vaultInfo', 'vaultAccess']" />
    </div>
    <div class="divider" />
    <page-vault-summary />
  </div>
</template>

<script>
import Faqs from '../components/ui/Faqs'
import PageVaultSummary from './vault/Summary'
export default {
  name: 'PageVault',
  components: { Faqs, PageVaultSummary },
  data: () => ({
    tab: 0,
    tabs: [
      {
        key: 'vault',
        label: 'Vault'
      },
      {
        key: 'settings',
        label: 'Settings'
      }
    ]
  })
}
</script>

<style lang="scss"></style>
