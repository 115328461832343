<template>
  <div>
    <accordion-main v-model="panel">
      <accordion-main-panel
        v-for="(item, index) in panels"
        :ref="`panel-${index}`"
        :key="index"
        :title="item.title"
        :subtitle="item.subtitle"
        :id="`panel-${item.key}`"
        class="panel-vault"
      >
        <template v-slot:header-prepend>
          <v-list-item-avatar class="section-avatar">
            <v-icon color="black">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
        </template>
        <component
          v-if="item.key"
          :is="`vault-${item.key}`"
          :accessStatus="accessStatus"
          @accessDenied="accessWarning"
        />
      </accordion-main-panel>
      <v-overlay
        :value="accessStatus === 'unactivated'"
        absolute
        class="text-center"
        color="black"
        opacity="0.6"
      >
        <v-icon
          size="48"
          class="mb-4"
          color="white"
          >mdi-lock-outline</v-icon
        >
        <p class="text-white font-weight-bold">
          Get access to your digital vault when you purchase your Will.
        </p>
        <v-btn
          v-bind="smallButton"
          @click="$router.push('/checkout')"
          >Buy now</v-btn
        >
      </v-overlay>
    </accordion-main>

    <dialog-small
      title="Digital Vault"
      :open.sync="openAccess"
    >
      <div class="pa-4">
        <p>
          The digital vault feature of your account comes included when you
          purchase your Will.
        </p>
        <div>Get your Will now to start saving details in to your account.</div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn
          text
          tile
          x-large
          block
          download
          @click="$router.push('/checkout')"
          >Purchase Will</v-btn
        >
      </template>
    </dialog-small>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import AccordionMain from "../../components/wrapper/AccordionMain"
import AccordionMainPanel from "../../components/wrapper/AccordionMainPanel"
import DialogSmall from "../../components/wrapper/DialogSmall"
const VaultContacts = () => import("./sections/Contacts")
const VaultMessages = () => import("./sections/Messages")
const VaultAssets = () => import("./sections/Assets")
const VaultLiabilities = () => import("./sections/Liabilities")
export default {
  name: "PageVaultSummary",
  components: {
    AccordionMain,
    AccordionMainPanel,
    DialogSmall,
    VaultContacts,
    VaultMessages,
    VaultAssets,
    VaultLiabilities,
  },
  data: () => ({
    panel: null,
    openAccess: false,
    panels: [
      {
        title: "Personal Messages",
        subtitle: "Write messages for your loved ones",
        key: "messages",
        icon: "mdi-message-outline",
      },
      {
        title: "Assets",
        subtitle: "List your assets",
        key: "assets",
        icon: "mdi-finance",
      },
      {
        title: "Liabilities",
        subtitle: "List your debts",
        key: "liabilities",
        icon: "mdi-credit-card-multiple-outline",
      },
      {
        title: "Important Contacts",
        subtitle: "Update and manage important contacts",
        key: "contacts",
        icon: "mdi-account-multiple-outline",
      },
    ],
  }),
  methods: {
    accessWarning() {
      this.openAccess = true
    },
  },
  computed: {
    ...mapGetters("account", ["user_access"]),
    accessStatus() {
      if (this.user_access.hasExpired) {
        return "expired"
      }
      if (this.user_access.hasPurchased) {
        return "active"
      }

      return "unactivated"
    },
  },
}
</script>

<style lang="scss">
.panel-vault {
  .v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
